<template>
  <div class="imagemap-message">
    <div class="chat-item-imagemap">
      <img
        :src="data.baseUrl"
        :width="(data.baseSize.width)? data.baseSize.width : ''"
        :height="(data.baseSize.height)? data.baseSize.height : ''"
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImagemapMessage',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
.imagemap-message{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
  -webkit-box-orient: horizontal;
  box-sizing: border-box;
}
.chat-item-imagemap {
  display: flex;
  position: relative;
  margin-left: 6px;
}
.chat-item-imagemap>img {
  border-style: none;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
}
</style>
