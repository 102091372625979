<template>
  <div class="image-message">
    <div class="chat-item-image">
      <img :src="data.originalContentUrl">
    </div>
  </div>
</template>

<script>

export default {
  name: 'ImageMessage',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
.image-message{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
  -webkit-box-orient: horizontal;
  box-sizing: border-box;
}
.chat-item-image {
  display: flex;
  position: relative;
  margin-left: 6px;
}
.chat-item-image>img {
  border-style: none;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
}
</style>
