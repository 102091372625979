<template>
  <div
    v-if="$can('read', 'Chatmessage')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pr-0"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1 w-100"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  md="9"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pl-md-0 pr-md-0"
                >
                  <div class="d-flex align-items-center justify-content-end w-100">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                    <b-button
                      v-if="$can('create', 'Chatmessage')"
                      variant="primary"
                      style="width: 100%; max-width: 150px;"
                      @click="addChatmessageForm"
                    >
                      {{ `${$t('Add')} ${$t('Chat Message')}` }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="12"
              md="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <span class="text-muted mr-2">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalChatmessages"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>

        </div>
        <b-table
          ref="refChatmessageListTable"
          :items="getListChatmessage"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <!-- Column: Type/ID -->
          <template #cell(id)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="AwardIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ `${data.item.type_name}` }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="TagIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ `${data.item.name}` }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    {{ $t('Create Date/Time') }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.create_date_show }}
                  </span>
                  <span style="margin-left: 1rem; margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.create_time_show }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    {{ $t('Update Date/Time') }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.update_date_show }}
                  </span>
                  <span style="margin-left: 1rem; margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.update_time_show }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="HashIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    <b-badge
                      pill
                      :variant="(skin === 'dark')? `light-info` : `info` "
                      class="text-capitalize"
                    >
                      {{ data.item.id }}
                    </b-badge>
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="font-size: 14px;">{{ $t('Postback Data') }}</span>
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="14"
                    />
                  </span>
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="Link2Icon"
                      size="14"
                    />
                  </span>
                  <span
                    style="font-size: 14px;"
                    class="text-primary"
                  >
                    {{ data.item.postback_data }}
                  </span>
                  <b-button
                    size="sm"
                    class="ml-1"
                    variant="success"
                    @click="onCopy(data.item.postback_data)"
                  >
                    {{ $t('Copy') }}
                  </b-button>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: message -->
          <template #cell(message)="data">
            <div
              style="margin-top: 0.3rem; max-width: 620px;"
            >
              <line-chat-message :data="data.item.message" />
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">

              <a
                class="action-icon"
                @click.stop="editChatmessageForm(data)"
              >
                <feather-icon
                  v-if="$can('edit', 'Chatmessage')"
                  :id="`chatmessage-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('edit', 'Chatmessage')"
                  :title="`${$t('Edit')} ${$t('Chatmessage')}`"
                  :target="`chatmessage-row-${data.item.id}-edit-icon`"
                  :delay="{ show: 3000, hide: 50 }"
                  placement="right"
                />
              </a>
              <a
                class="action-icon"
                @click.stop="deleteChatmessageForm(data)"
              >
                <feather-icon
                  v-if="$can('delete', 'Chatmessage')"
                  :id="`chatmessage-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('delete', 'Chatmessage')"
                  :title="`${$t('Delete')} ${$t('Chatmessage')}`"
                  :target="`chatmessage-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 3000, hide: 50 }"
                  placement="right"
                />
              </a>

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pr-0"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1 w-100"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  md="9"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pl-md-0 pr-md-0"
                >
                  <div class="d-flex align-items-center justify-content-end w-100">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                    <b-button
                      v-if="$can('create', 'Chatmessage')"
                      variant="primary"
                      style="width: 100%; max-width: 150px;"
                      @click="addChatmessageForm"
                    >
                      {{ `${$t('Add')} ${$t('Chat Message')}` }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="12"
              md="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <span class="text-muted mr-2">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalChatmessages"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <chatmessage-form
        v-model="shallShowChatmessageFormModal"
        :chatmessage-data="chatmessageData"
        :type-chatmessage-form="typeChatmessageForm"
        @update-chatmessage-form="updateChatmessageForm"
        @discard-chatmessage-form="discardChatmessageForm"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable,
  BBadge, BPagination, BTooltip, BListGroup, BListGroupItem,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import LineChatMessage from '@core/components/line-chat-message/LineChatMessage.vue'
import useChatmessageList from './useChatmessageList'
import chatmessageStoreModule from './chatmessageStoreModule'
import ChatmessageForm from './ChatmessageForm.vue'

export default {
  components: {
    LineChatMessage,
    ChatmessageForm,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      defaultData: {},
      chatmessageData: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      collapsedFilter: true,
      typeOptions: [],
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  mounted() {
    this.locale = this.$i18n.locale
    this.getDefaultChatmessage()
  },
  methods: {
    getDefaultChatmessage() {
      this.showOverlay = true
      store
        .dispatch('chatmessage-store/getDefaultChatmessage', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.defaultData = response.data
          this.chatmessageData = this.defaultData
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addChatmessageForm() {
      this.showOverlay = true
      store
        .dispatch('chatmessage-store/getDefaultChatmessage', { language: this.$i18n.locale, lineOaId: this.lineOaId })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.defaultData = response.data
          this.chatmessageData = response.data
          this.typeChatmessageForm = 'Add'
          this.shallShowChatmessageFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editChatmessageForm(data) {
      this.showOverlay = true
      store
        .dispatch('chatmessage-store/getChatmessage', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.chatmessageData = response.data
          this.chatmessageData.chatmessageType = this.defaultData.chatmessageType
          this.typeChatmessageForm = 'Edit'
          this.shallShowChatmessageFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    deleteChatmessageForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('chatmessage-store/deleteChatmessage', data.item.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateChatmessageForm() {
      this.refetchData()
    },
    discardChatmessageForm() {
      this.refetchData()
    },
  },
  setup() {
    const shallShowChatmessageFormModal = ref(false)
    const typeChatmessageForm = ref('')

    if (!store.hasModule('chatmessage-store')) store.registerModule('chatmessage-store', chatmessageStoreModule)
    onUnmounted(() => {
      if (store.hasModule('chatmessage-store')) store.unregisterModule('chatmessage-store')
    })

    const {
      getListChatmessage,
      tableColumns,
      perPage,
      currentPage,
      totalChatmessages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      local,
      refChatmessageListTable,
      refetchData,
    } = useChatmessageList()

    return {
      shallShowChatmessageFormModal,
      typeChatmessageForm,

      getListChatmessage,
      tableColumns,
      perPage,
      currentPage,
      totalChatmessages,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      local,
      refChatmessageListTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/lineapi/chatmessage.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #EB008B !important;
  }
}
</style>

<style lang="scss" scope>
@import '@core/scss/base/bootstrap-extended/include';
.media-heading {
  color: $body-color;
  margin-bottom: 0;
  line-height: 1.2;
}
</style>
