export default class Utils {
  static upperAllDigit(str) {
    if (Number.isNaN(Number(str.charAt(0)))) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
    return (
      str.charAt(0).toUpperCase() + str.charAt(1).toUpperCase() + str.slice(2)
    )
  }

  static upper1Digit(str) {
    return str.charAt(0).toUpperCase()
  }

  static upperToDigit(str) {
    return str.charAt(0).toUpperCase() + str.substring(1, 2)
  }

  static getFlexBubbleClass(data) {
    const {
      size,
    } = data
    const customClass = ['lyItem']
    customClass.push(`Ly${this.upperToDigit(size || 'medium')}`)
    return customClass
  }

  static getFlexBoxClass(data) {
    const {
      layout,
      position,
      flex,
      spacing,
      margin,
      borderWidth,
      cornerRadius,
      justifyContent,
      alignItems,
      offsetTop,
      offsetBottom,
      offsetStart,
      offsetEnd,
      paddingAll,
      paddingTop,
      paddingBottom,
      paddingStart,
      paddingEnd,
    } = data
    const customClass = ['MdBx']

    if (layout === 'baseline') {
      customClass.push('hr')
      customClass.push('bl')
    }
    if (layout === 'horizontal') {
      customClass.push('hr')
    }
    if (layout === 'vertical') {
      customClass.push('vr')
    }

    if (flex <= 3) {
      customClass.push(flex >= 0 ? `fl${flex}` : '')
    }

    if (position === 'absolute') {
      customClass.push('ExAbs')
    }

    if (spacing && spacing.indexOf('px') < 0) {
      customClass.push(spacing ? `spc${this.upperAllDigit(spacing)}` : '')
    }

    if (margin && margin.indexOf('px') < 0) {
      const ExMgnT = {
        none: 'ExMgnTNone',
        xs: 'ExMgnTXs',
        sm: 'ExMgnTSm',
        md: 'ExMgnTMd',
        lg: 'ExMgnTLg',
        xl: 'ExMgnTXl',
        xxl: 'ExMgnTXxl',
      }
      customClass.push(ExMgnT[margin] || '')
    }

    if (borderWidth && borderWidth.indexOf('px') < 0) {
      const ExBdr = {
        none: 'ExBdrWdtNone',
        light: 'ExBdrWdtLgh',
        normal: 'ExBdrWdtNml',
        medium: 'ExBdrWdtMdm',
        'semi-bold': 'ExBdrWdtSbd',
        bold: 'ExBdrWdtBld',
      }
      customClass.push(ExBdr[borderWidth] || '')
    }

    if (cornerRadius && cornerRadius.indexOf('px') < 0) {
      customClass.push(
        cornerRadius ? `ExBdrRad${this.upperAllDigit(cornerRadius)}` : '',
      )
    }
    if (justifyContent) {
      const jfc = {
        center: 'itms-jfcC',
        'flex-start': 'itms-jfcS',
        'flex-end': 'itms-jfcE',
        'space-between': 'itms-jfcSB',
        'space-around': 'itms-jfcSA',
        'space-evenly': 'itms-jfcSE',
      }
      customClass.push(jfc[justifyContent] || '')
    }

    if (alignItems) {
      const alg = {
        center: 'itms-algC',
        'flex-start': 'itms-algS',
        'flex-end': 'itms-algE',
      }
      customClass.push(alg[alignItems] || '')
    }

    if (offsetTop && offsetTop.indexOf('px') < 0) {
      customClass.push(offsetTop ? `ExT${this.upperAllDigit(offsetTop)}` : '')
    }
    if (offsetBottom && offsetBottom.indexOf('px') < 0) {
      customClass.push(
        offsetBottom ? `ExB${this.upperAllDigit(offsetBottom)}` : '',
      )
    }
    if (offsetStart && offsetStart.indexOf('px') < 0) {
      customClass.push(
        offsetStart ? `ExL${this.upperAllDigit(offsetStart)}` : '',
      )
    }
    if (offsetEnd && offsetEnd.indexOf('px') < 0) {
      customClass.push(offsetEnd ? `ExR${this.upperAllDigit(offsetEnd)}` : '')
    }

    if (paddingAll && paddingAll.indexOf('px') < 0) {
      customClass.push(
        paddingAll ? `ExPadA${this.upperAllDigit(paddingAll)}` : '',
      )
    }

    if (paddingTop && paddingTop.indexOf('px') < 0) {
      customClass.push(
        paddingTop ? `ExPadT${this.upperAllDigit(paddingTop)}` : '',
      )
    }

    if (paddingBottom && paddingBottom.indexOf('px') < 0) {
      customClass.push(
        paddingBottom ? `ExPadB${this.upperAllDigit(paddingBottom)}` : '',
      )
    }

    if (paddingStart && paddingStart.indexOf('px') < 0) {
      customClass.push(
        paddingStart ? `ExPadL${this.upperAllDigit(paddingStart)}` : '',
      )
    }

    if (paddingEnd && paddingEnd.indexOf('px') < 0) {
      customClass.push(
        paddingEnd ? `ExPadR${this.upperAllDigit(paddingEnd)}` : '',
      )
    }

    return customClass
  }

  static getFlexButtonClass(data) {
    const {
      flex,
      margin,
      position,
      height,
      style,
      gravity,
      offsetTop,
      offsetBottom,
      offsetStart,
      offsetEnd,
    } = data
    const customClass = ['MdBtn']

    if (flex <= 3) {
      customClass.push(flex >= 0 ? `fl${flex}` : '')
    }

    if (position === 'absolute') {
      customClass.push('ExAbs')
    }

    customClass.push(
      gravity === 'bottom' || gravity === 'center'
        ? `grv${this.upper1Digit(gravity)}`
        : '',
    )

    if (margin && margin.indexOf('px') < 0) {
      customClass.push(margin ? `ExMgnT${this.upperAllDigit(margin)}` : '')
    }

    if (offsetTop && offsetTop.indexOf('px') < 0) {
      customClass.push(offsetTop ? `ExT${this.upperAllDigit(offsetTop)}` : '')
    }

    if (offsetBottom && offsetBottom.indexOf('px') < 0) {
      customClass.push(
        offsetBottom ? `ExB${this.upperAllDigit(offsetBottom)}` : '',
      )
    }

    if (offsetStart && offsetStart.indexOf('px') < 0) {
      customClass.push(
        offsetStart ? `ExL${this.upperAllDigit(offsetStart)}` : '',
      )
    }

    if (offsetEnd && offsetEnd.indexOf('px') < 0) {
      customClass.push(offsetEnd ? `ExR${this.upperAllDigit(offsetEnd)}` : '')
    }

    const FntSty = {
      link: 'ExBtnL',
      primary: 'ExBtn1',
      secondary: 'ExBtn2',
    }
    customClass.push(FntSty[style] || 'ExBtnL')

    customClass.push(
      !height || height === 'md' ? '' : `Ex${this.upperAllDigit(height)}`,
    )

    return customClass
  }

  static getFlexFillerClass(data) {
    const {
      flex,
    } = data
    const customClass = ['mdBxFiller']

    if (flex >= 0 && flex <= 3) {
      customClass.push(`fl${flex}`)
    }

    return customClass
  }

  static getFlexImageClass(data) {
    const {
      aspectMode,
      size,
      position,
      flex,
      margin,
      align,
      gravity,
      offsetTop,
      offsetBottom,
      offsetStart,
      offsetEnd,
    } = data
    const customClass = ['MdImg']

    const aspMd = (aspectMode) || 'fit'
    customClass.push(`Ex${this.upperAllDigit(aspMd)}`)
    customClass.push(flex && flex >= 0 ? `fl${flex}` : '')

    const sz = (size) || 'md'
    if (sz && sz.indexOf('px') < 0) {
      customClass.push(`Ex${this.upperAllDigit(sz)}`)
    }

    if (position === 'absolute') {
      customClass.push('ExAbs')
    }

    if (!(margin && margin.indexOf('px') >= 0)) {
      customClass.push(margin ? `ExMgnT${this.upperAllDigit(margin)}` : '')
    }
    customClass.push(
      align === 'start' || align === 'end'
        ? `alg${this.upper1Digit(align)}`
        : '',
    )
    customClass.push(
      gravity === 'bottom' || gravity === 'center'
        ? `grv${this.upper1Digit(gravity)}`
        : '',
    )

    if (offsetTop && offsetTop.indexOf('px') < 0) {
      customClass.push(offsetTop ? `ExT${this.upperAllDigit(offsetTop)}` : '')
    }

    if (offsetBottom && offsetBottom.indexOf('px') < 0) {
      customClass.push(
        offsetBottom ? `ExB${this.upperAllDigit(offsetBottom)}` : '',
      )
    }

    if (offsetStart && offsetStart.indexOf('px') < 0) {
      customClass.push(
        offsetStart ? `ExB${this.upperAllDigit(offsetStart)}` : '',
      )
    }

    if (offsetEnd && offsetEnd.indexOf('px') < 0) {
      customClass.push(offsetEnd ? `ExB${this.upperAllDigit(offsetEnd)}` : '')
    }

    return customClass
  }

  static getFlexIconClass(data) {
    const {
      size,
      // aspectRatio,
      position,
      margin,
      offsetTop,
      offsetBottom,
      offsetStart,
      offsetEnd,
    } = data
    const customClass = ['MdIco', 'fl0']

    const sz = (size) || 'md'
    if (!(sz && sz.indexOf('px') >= 0)) {
      customClass.push(`Ex${this.upperAllDigit(sz)}`)
    }

    if (position === 'absolute') {
      customClass.push('ExAbs')
    }

    if (!(margin && margin.indexOf('px') >= 0)) {
      customClass.push(margin ? `ExMgnL${this.upperAllDigit(margin)}` : '')
    }

    if (offsetTop && offsetTop.indexOf('px') < 0) {
      customClass.push(offsetTop ? `ExT${this.upperAllDigit(offsetTop)}` : '')
    }
    if (offsetBottom && offsetBottom.indexOf('px') < 0) {
      customClass.push(
        offsetBottom ? `ExB${this.upperAllDigit(offsetBottom)}` : '',
      )
    }
    if (offsetStart && offsetStart.indexOf('px') < 0) {
      customClass.push(
        offsetStart ? `ExL${this.upperAllDigit(offsetStart)}` : '',
      )
    }
    if (offsetEnd && offsetEnd.indexOf('px') < 0) {
      customClass.push(offsetEnd ? `ExR${this.upperAllDigit(offsetEnd)}` : '')
    }

    return customClass
  }

  static getFlexTextClass(data) {
    const {
      flex,
      margin,
      size,
      position,
      align,
      gravity,
      weight,
      style,
      decoration,
      wrap,
      offsetTop,
      offsetBottom,
      offsetStart,
      offsetEnd,
    } = data
    const customClass = ['MdTxt']

    if (flex >= 0) {
      customClass.push(`fl${flex}`)
    }

    const sz = (size) || 'md'
    if (!(sz && sz.indexOf('px') >= 0)) {
      customClass.push(`Ex${this.upperAllDigit(sz)}`)
    }

    if (position === 'absolute') {
      customClass.push('ExAbs')
    }

    if (!(margin && margin.indexOf('px') >= 0)) {
      customClass.push(margin ? `ExMgnT${this.upperAllDigit(margin)}` : '')
    }

    if (align === 'start' || align === 'end' || align === 'center') {
      customClass.push(`ExAlg${this.upper1Digit(align)}`)
    }

    if (gravity === 'bottom' || gravity === 'center') {
      customClass.push(`grv${this.upper1Digit(gravity)}`)
    }

    if (offsetTop && offsetTop.indexOf('px') < 0) {
      customClass.push(offsetTop ? `ExT${this.upperAllDigit(offsetTop)}` : '')
    }
    if (offsetBottom && offsetBottom.indexOf('px') < 0) {
      customClass.push(
        offsetBottom ? `ExB${this.upperAllDigit(offsetBottom)}` : '',
      )
    }
    if (offsetStart && offsetStart.indexOf('px') < 0) {
      customClass.push(
        offsetStart ? `ExL${this.upperAllDigit(offsetStart)}` : '',
      )
    }
    if (offsetEnd && offsetEnd.indexOf('px') < 0) {
      customClass.push(offsetEnd ? `ExR${this.upperAllDigit(offsetEnd)}` : '')
    }

    if (weight === 'bold') {
      customClass.push('ExWB')
    }

    if (style) {
      const FntSty = {
        normal: 'ExFntStyNml',
        italic: 'ExFntStyIt',
      }
      customClass.push(FntSty[style] || '')
    }

    if (decoration) {
      const ExTxtDec = {
        'line-through': 'ExTxtDecLt',
        underline: 'ExTxtDecUl',
        none: 'ExTxtDecNone',
      }
      customClass.push(ExTxtDec[decoration] || '')
    }

    if (wrap) {
      customClass.push('ExWrap')
    }

    return customClass
  }

  static getFlexSpanClass(data) {
    const {
      size,
      weight,
      style,
      decoration,
    } = data
    const customClass = ['MdSpn']

    const sz = (size) || 'md'
    if (sz && sz.indexOf('px') < 0) {
      customClass.push(`Ex${this.upperAllDigit(sz)}`)
    }

    if (weight) {
      customClass.push(weight === 'bold' ? 'ExWB' : '')
    }

    if (style) {
      const FntSty = {
        normal: 'ExFntStyNml',
        italic: 'ExFntStyIt',
      }
      customClass.push(FntSty[style] || '')
    }

    if (decoration) {
      const ExTxtDec = {
        'line-through': 'ExTxtDecLt',
        underline: 'ExTxtDecUl',
        none: 'ExTxtDecNone',
      }
      customClass.push(ExTxtDec[decoration] || '')
    }

    return customClass
  }

  static getFlexSpacerClass(data) {
    const {
      size,
    } = data
    const customClass = ['mdBxSpacer', 'fl0']

    const sz = (size) || 'md'
    if (sz && sz.indexOf('px') < 0) {
      customClass.push(`spc${this.upperAllDigit(sz)}`)
    }

    return customClass
  }

  static getFlexSeparatorClass(data) {
    const {
      margin,
    } = data
    const customClass = ['fl0', 'MdSep']

    if (!(margin && margin.indexOf('px') >= 0)) {
      customClass.push(margin ? `ExMgnT${this.upperAllDigit(margin)}` : '')
    }

    return customClass
  }

  static getFlexVideoClass(data) {
    const {
      // aspectRatio,
      altContent,
    } = data
    const customClass = ['MdVdo']

    if (altContent) {
      const {
        aspectMode,
        size,
      } = data.altContent
      const aspMd = (aspectMode) || ''
      if (aspMd !== '') {
        customClass.push(`Ex${this.upperAllDigit(aspMd)}`)
      }

      const sz = (size) || 'md'
      if (sz && sz.indexOf('px') < 0) {
        customClass.push(`Ex${this.upperAllDigit(sz)}`)
      }
    }

    return customClass
  }

  static getFlexData(data) {
    const type = ('type' in data) ? data.type : ''
    return type
  }
}
