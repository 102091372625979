<template>
  <div
    :class="spacerClass.join(' ')"
  />
</template>

<script>
import Utils from '../utils'

export default {
  name: 'FlexSpacer',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    spacerClass() {
      return Utils.getFlexSpacerClass(this.data)
    },
  },

}
</script>

<style>

</style>
