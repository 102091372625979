<template>
  <div
    :class="customClass.join(' ')"
    :style="style"
  >
    <a
      :style="`${backgroundColor}`"
      @click="onClick(data)"
    >
      <div>
        {{ ('label' in data.action)? data.action.label : '' }}
      </div>
    </a>
  </div>
</template>

<script>
import Utils from '../utils'

export default {
  name: 'FlexButton',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customClass() {
      return Utils.getFlexButtonClass(this.data)
    },
    style() {
      return `${this.marginTop}${this.offsetTop}${this.offsetBottom}${this.offsetStart}${this.offsetEnd}${this.flex}`
    },
    backgroundColor() {
      if ('color' in this.data) {
        return `background-color: ${this.data.color};`
      }
      return ''
    },
    marginTop() {
      if (('margin' in this.data)) {
        if (this.data.margin.indexOf('px') >= 0) {
          return `margin-top: ${this.data.margin};`
        }
      }
      return ''
    },
    offsetTop() {
      if (('offsetTop' in this.data)) {
        if (this.data.offsetTop.indexOf('px') >= 0) {
          return `top: ${this.data.offsetTop};`
        }
      }
      return ''
    },
    offsetBottom() {
      if (('offsetBottom' in this.data)) {
        if (this.data.offsetBottom.indexOf('px') >= 0) {
          return `bottom: ${this.data.offsetBottom};`
        }
      }
      return ''
    },
    offsetStart() {
      if (('offsetStart' in this.data)) {
        if (this.data.offsetStart.indexOf('px') >= 0) {
          return `left: ${this.data.offsetStart};`
        }
      }
      return ''
    },
    offsetEnd() {
      if (('offsetEnd' in this.data)) {
        if (this.data.offsetEnd.indexOf('px') >= 0) {
          return `right: ${this.data.offsetEnd};`
        }
      }
      return ''
    },
    flex() {
      if ('flex' in this.data) {
        if (this.data.flex > 3) {
          return `-webkit-box-flex: ${this.data.flex};flex-grow: ${this.data.flex};`
        }
      }
      return ''
    },
  },
  methods: {
    onClick(data) {
      this.$emit('action', data.action)
    },
  },
}
</script>

<style>

</style>
