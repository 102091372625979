<template>
  <div
    :class="customVideoClass.join(' ')"
    :style="style"
  >
    <div :style="width">
      <a
        v-if="videoUrl === ''"
        :style="paddingBottom"
        @click="onClick(data)"
      >
        <span
          :style="`${backgroundPreview}`"
        />
      </a>
      <a
        v-else
        @click="onClick(data)"
      >
        <vue-viaudio
          :kind="'video'"
          :controls="true"
          :src="[videoUrl]"
          :poster="videoPreview"
          playinline
          :style="{width: '100%'}"
        />
      </a>
    </div>
  </div>
</template>

<script>
import VueViaudio from '@dongido/vue-viaudio'
import Utils from '../utils'

export default {
  name: 'FlexVideo',
  components: {
    VueViaudio,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customVideoClass() {
      return Utils.getFlexVideoClass(this.data)
    },
    style() {
      return ''
    },
    width() {
      if (('size' in this.data)) {
        if (this.data.size.indexOf('px') >= 0) {
          return `width: ${this.data.size};`
        }
      }
      if ('altContent' in this.data) {
        if ('size' in this.data.altContent) {
          if (this.data.altContent.size.indexOf('px') >= 0) {
            return `width: ${this.data.altContent.size};`
          }
        }
      }
      return ''
    },
    videoUrl() {
      if ('url' in this.data) {
        return `${this.data.url}`
      }
      return ''
    },
    videoPreview() {
      if ('previewUrl' in this.data) {
        return `${this.data.previewUrl}`
      }
      if ('altContent' in this.data) {
        if ('url' in this.data.altContent) {
          return `${this.data.altContent.url}`
        }
      }
      return ''
    },
    backgroundPreview() {
      if ('previewUrl' in this.data) {
        return `background-image: url(${this.data.previewUrl});`
      }
      if ('altContent' in this.data) {
        if ('url' in this.data.altContent) {
          return `background-image: url(${this.data.altContent.url});`
        }
      }
      return ''
    },
    paddingBottom() {
      if ('aspectRatio' in this.data) {
        const ratio = this.data.aspectRatio.split(':')
        return `padding-bottom: ${(Number(ratio[1]) * 100) / Number(ratio[0])}%;`
      }
      if ('altContent' in this.data) {
        if ('aspectRatio' in this.data.altContent) {
          const ratio = this.data.altContent.aspectRatio.split(':')
          return `padding-bottom: ${(Number(ratio[1]) * 100) / Number(ratio[0])}%;`
        }
      }
      return `padding-bottom: ${100}%;`
    },
  },
  methods: {
    onClick(data) {
      this.$emit('action', data.action)
    },
  },
}
</script>

<style>

</style>
