<template>
  <div>
    <b-button
      v-if="isCarousel(data)"
      style="display: flex; float: left;"
      @click="onLeft"
    >
      <feather-icon
        size="30"
        icon="ChevronLeftIcon"
        style="z-index: 999;"
      />
    </b-button>
    <div
      ref="refFlexMessage"
      class="LySlider"
    >
      <component
        :is="isComponent(data.contents)"
        class="lyInner"
        :data="data.contents"
        @action="onAction"
      />
    </div>
    <b-button
      v-if="isCarousel(data)"
      style="display: flex; float: right;"
      @click="onRight"
    >
      <feather-icon
        size="30"
        icon="ChevronRightIcon"
        style="z-index: 999;"
      />
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import FlexBubble from './FlexBubble.vue'
import FlexCarousel from './FlexCarousel.vue'

export default {
  name: 'FlexMessage',
  components: {
    BButton,
    FlexBubble,
    FlexCarousel,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isComponent(data) {
      if ('type' in data) {
        return `flex-${data.type}`
      }
      return 'div'
    },
    onAction(action) {
      this.$emit('action', action)
    },
    isCarousel(data) {
      if ('type' in data) {
        if (data.type === 'flex') {
          const { contents } = data
          if (contents) {
            const { type } = contents
            if (type === 'carousel') {
              return true
            }
          }
        }
      }
      return false
    },
    onScroll(scrollPixels, duration) {
      const element = this.$refs.refFlexMessage
      const scrollPos = element.scrollLeft
      if (!((scrollPos === 0 || scrollPixels > 0)
        && (element.clientWidth + scrollPos === element.scrollWidth || scrollPixels < 0))) {
        const startTime = 'now' in window.performance
          ? performance.now()
          : new Date().getTime()

        const scroll = timestamp => {
          // Calculate the timeelapsed
          const timeElapsed = timestamp - startTime
          // Calculate progress
          const progress = Math.min(timeElapsed / duration, 1)
          // Set the scrolleft
          element.scrollLeft = scrollPos + scrollPixels * progress
          // Check if elapsed time is less then duration then call the requestAnimation, otherwise exit
          if (timeElapsed < duration) {
            // Request for animation
            window.requestAnimationFrame(scroll)
          }
        }
        window.requestAnimationFrame(scroll)
      }
    },
    onLeft() {
      this.onScroll(-600, 800)
    },
    onRight() {
      this.onScroll(600, 800)
    },
  },

}
</script>

<style>

</style>
