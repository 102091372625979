<template>
  <div class="sticker-message">
    <div class="chat-item-sticker">
      <img
        :src="stickerMessage"
        @error="imageUrlAlt"
      >
    </div>
  </div>
</template>

<script>

export default {
  name: 'StickerMessage',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    stickerMessage() {
      const { stickerId } = this.data
      const stickerUrl = `https://stickershop.line-scdn.net/stickershop/v1/sticker/${stickerId}/android/sticker.png`
      return stickerUrl
    },
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    imageUrlAlt(event) {
      // console.log(event)
    },
  },
}
</script>

<style lang="css" scoped>
.sticker-message{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
  -webkit-box-orient: horizontal;
  box-sizing: border-box;
}
.chat-item-sticker {
  display: flex;
  position: relative;
  margin-left: 6px;
}
.chat-item-sticker>img {
  border-style: none;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  max-width: 110px;
  max-height: 110px;
}
</style>
