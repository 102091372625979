<template>
  <div
    :class="textClass.join(' ')"
    :style="style"
  >
    <p>
      {{ haveContents(data) }}
      <flex-span
        v-for="(content, index) in data.contents"
        :key="index"
        :data="content"
      />
    </p>
  </div>
</template>

<script>
import Utils from '../utils'
import FlexSpan from './FlexSpan.vue'

export default {
  name: 'FlexText',
  components: {
    FlexSpan,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    textClass() {
      return Utils.getFlexTextClass(this.data)
    },
    style() {
      return `${this.color}${this.flex}${this.offsetTop}${this.offsetBottom}${this.offsetStart}${this.offsetEnd}${this.marginTop}`
    },
    flex() {
      if ('flex' in this.data) {
        if (this.data.flex > 3) {
          return `-webkit-box-flex: ${this.data.flex};flex-grow: ${this.data.flex};`
        }
      }
      return ''
    },
    offsetTop() {
      if ('offsetTop' in this.data) {
        if (this.data.offsetTop.indexOf('px') >= 0) {
          return `top: ${this.data.offsetTop};`
        }
      }
      return ''
    },
    offsetBottom() {
      if ('offsetBottom' in this.data) {
        if (this.data.offsetBottom.indexOf('px') >= 0) {
          return `bottom: ${this.data.offsetBottom};`
        }
      }
      return ''
    },
    offsetStart() {
      if ('offsetStart' in this.data) {
        if (this.data.offsetStart.indexOf('px') >= 0) {
          return `left: ${this.data.offsetStart};`
        }
      }
      return ''
    },
    offsetEnd() {
      if ('offsetEnd' in this.data) {
        if (this.data.offsetEnd.indexOf('px') >= 0) {
          return `right: ${this.data.offsetEnd};`
        }
      }
      return ''
    },
    marginTop() {
      if ('marginTop' in this.data) {
        if (this.data.margin.indexOf('px') >= 0) {
          return `margin-top: ${this.data.margin};`
        }
      }
      return ''
    },
    color() {
      return ('color' in this.data) ? `color: ${this.data.color};` : ''
    },
  },
  methods: {
    haveContents(data) {
      const { text, contents } = data
      if (contents && contents.length > 0) {
        return ''
      }
      if (text) {
        return data.text
      }
      return ''
    },
  },

}
</script>

<style>

</style>
