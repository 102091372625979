<template>
  <span
    :class="spanClass.join(' ')"
    :style="`${color}${fontSize}`"
  >
    {{ ('text' in data)? data.text : '' }}
  </span>
</template>

<script>
import Utils from '../utils'

export default {
  name: 'FlexSpan',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    spanClass() {
      return Utils.getFlexSpanClass(this.data)
    },
    color() {
      return ('color' in this.data) ? `color: ${this.data.color};` : ''
    },
    fontSize() {
      if (('size' in this.data)) {
        if (this.data.size.indexOf('px') >= 0) {
          return `size: ${this.data.size};`
        }
      }
      return ''
    },
  },

}
</script>

<style>

</style>
