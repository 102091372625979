<template>
  <div
    :class="customImageClass.join(' ')"
    :style="style"
  >
    <div :style="width">
      <a
        :style="paddingBottom"
        @click="onClick(data)"
      >
        <span :style="`${backgroundImage}${backgroundColor}`" />
      </a>
    </div>
  </div>
</template>

<script>
import Utils from '../utils'

export default {
  name: 'FlexImage',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customImageClass() {
      return Utils.getFlexImageClass(this.data)
    },
    style() {
      return `${this.flex}`
    },
    flex() {
      if ('flex' in this.data) {
        if (this.data.flex > 3) {
          return `-webkit-box-flex: ${this.data.flex};flex-grow: ${this.data.flex};`
        }
      }
      return ''
    },
    width() {
      if (('size' in this.data)) {
        if (this.data.size.indexOf('px') >= 0) {
          return `width: ${this.data.size};`
        }
      }
      return ''
    },
    backgroundImage() {
      if ('url' in this.data) {
        return `background-image: url(${this.data.url});`
      }
      return ''
    },
    backgroundColor() {
      if ('backgroundColor' in this.data) {
        return `background-color: ${this.data.backgroundColor} !important;`
      }
      return ''
    },
    paddingBottom() {
      if ('aspectRatio' in this.data) {
        const ratio = this.data.aspectRatio.split(':')
        return `padding-bottom: ${(Number(ratio[1]) * 100) / Number(ratio[0])}%;`
      }
      return `padding-bottom: ${100}%;`
    },
  },
  methods: {
    onClick(data) {
      this.$emit('action', data.action)
    },
  },
}
</script>

<style>

</style>
