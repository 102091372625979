<template>
  <div
    :class="customClass.join(' ')"
    :style="style"
  >
    <component
      :is="`flex-${content.type}`"
      v-for="(content, index) in data.contents"
      :key="index"
      :data="content"
      @action="onAction"
    />
  </div>
</template>

<script>
import Utils from '../utils'
import FlexText from './FlexText.vue'
import FlexSeparator from './FlexSeparator.vue'
import FlexSpacer from './FlexSpacer.vue'
import FlexFiller from './FlexFiller.vue'
import FlexImage from './FlexImage.vue'
import FlexIcon from './FlexIcon.vue'
import FlexButton from './FlexButton.vue'

export default {
  name: 'FlexBox',
  components: {
    FlexText,
    FlexSeparator,
    FlexSpacer,
    FlexFiller,
    FlexImage,
    FlexIcon,
    FlexButton,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customClass() {
      return Utils.getFlexBoxClass(this.data)
    },
    style() {
      return `${this.background}${this.padding}${this.paddingTop}${this.paddingBottom}
        ${this.paddingStart}${this.paddingEnd}${this.offsetTop}${this.offsetBottom}
        ${this.offsetStart}${this.offsetEnd}${this.width}${this.height}${this.borderColor}
        ${this.borderWidth}${this.cornerRadius}${this.marginTop}${this.flex}`
    },
    background() {
      const { background } = this.data
      if (background && background.type === 'linearGradient') {
        const centerPosition = background.centerPosition
          ? background.centerPosition
          : '50%'
        if (background.centerColor) {
          return `background: linear-gradient(${background.angle}, ${background.startColor} 0%, ${background.centerColor} ${centerPosition}, ${background.endColor} 100%);`
        }
        return `background: linear-gradient(${background.angle}, ${background.startColor} 0%, ${background.endColor} 100%);`
      }
      return ('backgroundColor' in this.data) ? `background: ${this.data.backgroundColor};` : ''
    },
    padding() {
      if (('paddingAll' in this.data)) {
        if (this.data.paddingAll.indexOf('px') >= 0) {
          return `padding: ${this.data.paddingAll};`
        }
      }
      return ''
    },
    paddingTop() {
      if (('paddingTop' in this.data)) {
        if (this.data.paddingTop.indexOf('px') >= 0) {
          return `padding-top: ${this.data.paddingTop};`
        }
      }
      return ''
    },
    paddingBottom() {
      if (('paddingBottom' in this.data)) {
        if (this.data.paddingBottom.indexOf('px') >= 0) {
          return `padding-bottom: ${this.data.paddingBottom};`
        }
      }
      return ''
    },
    paddingStart() {
      if (('paddingStart' in this.data)) {
        if (this.data.paddingStart.indexOf('px') >= 0) {
          return `padding-left: ${this.data.paddingStart};`
        }
      }
      return ''
    },
    paddingEnd() {
      if (('paddingEnd' in this.data)) {
        if (this.data.paddingEnd.indexOf('px') >= 0) {
          return `padding-right: ${this.data.paddingEnd};`
        }
      }
      return ''
    },
    offsetTop() {
      if (('offsetTop' in this.data)) {
        if (this.data.offsetTop.indexOf('px') >= 0) {
          return `top: ${this.data.offsetTop};`
        }
      }
      return ''
    },
    offsetBottom() {
      if (('offsetBottom' in this.data)) {
        if (this.data.offsetBottom.indexOf('px') >= 0) {
          return `bottom: ${this.data.offsetBottom};`
        }
      }
      return ''
    },
    offsetStart() {
      if (('offsetStart' in this.data)) {
        if (this.data.offsetStart.indexOf('px') >= 0) {
          return `left: ${this.data.offsetStart};`
        }
      }
      return ''
    },
    offsetEnd() {
      if (('offsetEnd' in this.data)) {
        if (this.data.offsetEnd.indexOf('px') >= 0) {
          return `right: ${this.data.offsetEnd};`
        }
      }
      return ''
    },
    width() {
      return ('width' in this.data) ? `width: ${this.data.width};` : ''
    },
    height() {
      return ('height' in this.data) ? `height: ${this.data.height};` : ''
    },
    borderColor() {
      return ('borderColor' in this.data) ? `border-color: ${this.data.borderColor};` : ''
    },
    borderWidth() {
      if (('borderWidth' in this.data)) {
        if (this.data.borderWidth.indexOf('px') >= 0) {
          return `border-width: ${this.data.borderWidth};`
        }
      }
      return ''
    },
    cornerRadius() {
      if (('cornerRadius' in this.data)) {
        if (this.data.cornerRadius.indexOf('px') >= 0) {
          return `border-radius: ${this.data.cornerRadius};`
        }
      }
      return ''
    },
    marginTop() {
      if (('marginTop' in this.data)) {
        if (this.data.marginTop.indexOf('px') >= 0) {
          return `margin-top: ${this.data.marginTop};`
        }
      }
      return ''
    },
    flex() {
      if ('flex' in this.data) {
        if (this.data.flex > 3) {
          return `-webkit-box-flex: ${this.data.flex};flex-grow: ${this.data.flex};`
        }
      }
      return ''
    },
  },
  methods: {
    onAction(action) {
      this.$emit('action', action)
    },
  },
}
</script>

<style>

</style>
