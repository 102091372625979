<template>
  <div
    :class="customClass.join(' ')"
    :style="style"
  />
</template>

<script>
import Utils from '../utils'

export default {
  name: 'FlexFiller',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customClass() {
      return Utils.getFlexFillerClass(this.data)
    },
    style() {
      return `${this.flex}`
    },
    flex() {
      if ('flex' in this.data) {
        if (this.data.flex > 3) {
          return `-webkit-box-flex: ${this.data.flex};flex-grow: ${this.data.flex};`
        }
      }
      return ''
    },
  },
}
</script>

<style>

</style>
