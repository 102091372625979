import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getListChatmessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_chatmessage', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChatmessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_chatmessage', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addChatmessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_chatmessage', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editChatmessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_chatmessage', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteChatmessage(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_chatmessage/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultChatmessage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_chatmessage', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListChatmessageSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_chatmessage_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
