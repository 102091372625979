<template>
  <div
    :class="customClass.join(' ')"
    :style="style"
  >
    <span :style="`${width}${backgroundImage}`" />
  </div>
</template>

<script>
import Utils from '../utils'

export default {
  name: 'FlexIcon',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customClass() {
      return Utils.getFlexIconClass(this.data)
    },
    style() {
      return `${this.marginTop}${this.offsetTop}${this.offsetBottom}${this.offsetStart}${this.offsetEnd}${this.fontSize}`
    },
    marginTop() {
      if (('margin' in this.data)) {
        if (this.data.margin.indexOf('px') >= 0) {
          return `margin-top: ${this.data.margin};`
        }
      }
      return ''
    },
    backgroundImage() {
      if ('url' in this.data) {
        return `background-image: url(${this.data.url});`
      }
      return ''
    },
    offsetTop() {
      if (('offsetTop' in this.data)) {
        if (this.data.offsetTop.indexOf('px') >= 0) {
          return `top: ${this.data.offsetTop};`
        }
      }
      return ''
    },
    offsetBottom() {
      if (('offsetBottom' in this.data)) {
        if (this.data.offsetBottom.indexOf('px') >= 0) {
          return `bottom: ${this.data.offsetBottom};`
        }
      }
      return ''
    },
    offsetStart() {
      if (('offsetStart' in this.data)) {
        if (this.data.offsetStart.indexOf('px') >= 0) {
          return `left: ${this.data.offsetStart};`
        }
      }
      return ''
    },
    offsetEnd() {
      if (('offsetEnd' in this.data)) {
        if (this.data.offsetEnd.indexOf('px') >= 0) {
          return `right: ${this.data.offsetEnd};`
        }
      }
      return ''
    },
    fontSize() {
      if (('size' in this.data)) {
        if (this.data.size.indexOf('px') >= 0) {
          return `font-size: ${this.data.size};`
        }
      }
      return ''
    },
    width() {
      const ratio = '100'
      if ('aspectRatio' in this.data) {
        return 1
      }
      return `padding-bottom: ${Number(ratio[0]) / Number(ratio[1])}em;`
    },
  },
}
</script>

<style>

</style>
