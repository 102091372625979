<template>
  <div
    :class="separatorClass.join(' ')"
    :style="style"
  />
</template>

<script>
import Utils from '../utils'

export default {
  name: 'FlexSeparator',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    separatorClass() {
      return Utils.getFlexSeparatorClass(this.data)
    },
    style() {
      return `${this.borderColor}`
    },
    borderColor() {
      if ('color' in this.data) {
        return `border-color: ${this.data.color};`
      }
      return ''
    },
  },

}
</script>

<style>

</style>
