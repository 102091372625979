<template>
  <div>
    <component
      :is="isComponent(content)"
      v-for="(content, index) in data.contents"
      :key="index"
      :data="content"
      @action="onAction"
    />
  </div>
</template>

<script>
import FlexBubble from './FlexBubble.vue'

export default {
  name: 'FlexCarousel',
  components: {
    FlexBubble,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isComponent(data) {
      if ('type' in data) {
        return `flex-${data.type}`
      }
      return 'div'
    },
    onAction(action) {
      this.$emit('action', action)
    },
  },

}
</script>

<style>

</style>
