<template>
  <div class="text-message">
    <div class="chat-baloon">
      <span class="chat-item-text-message">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="textMessage" />
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TextMessage',
  components: {
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: '',
    }
  },
  computed: {
    textMessage() {
      const { text, emojis } = this.data
      let message = text
      if (emojis) {
        emojis.forEach(emoji => {
          const { index, emojiId, productId } = emoji
          if (index !== null && emojiId !== null && productId !== null) {
            const indexReplace = message.indexOf('$', index)
            const emojiUrl = `https://stickershop.line-scdn.net/sticonshop/v1/sticon/${productId}/android/${emojiId}.png`
            const replacement = `<img src="${emojiUrl}" width="18" height="18" />`
            message = message.substring(0, indexReplace) + replacement + message.substring(indexReplace + 1)
          }
        })
      }
      return message
    },
  },

}
</script>

<style lang="css" scoped>
.text-message{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
  -webkit-box-orient: horizontal;
  box-sizing: border-box;
}
.chat-baloon {
  background-color: #f2f0f0;
  border-radius: 14px;
  display: inline-block;
  position: relative;
  padding: 8px 14px;
  max-width: 300px;
  margin-left: 14px;
}
.chat-baloon::after {
  content: '';
  position: absolute;
  left: -6px;
  top: 0;
  width: 8px;
  height: 8px;
  border: 0 solid transparent;
  border-right-color: #f2f0f0;
  border-left: 0;
  border-top: 0;
  border-radius: 0 0 0 100%;
  border-bottom: 8px solid #f2f0f0;
  box-sizing: content-box;
  transform: rotate(-25deg);
}
.chat-item-text-message {
  color: #000;
  font-size: 14px;
  line-height: inherit;
  position: relative;
  text-align: left;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
</style>
