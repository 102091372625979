<template>
  <div class="video-message">
    <div class="chat-item-video">
      <vue-viaudio
        :kind="'video'"
        :controls="true"
        :src="[data.originalContentUrl]"
        :poster="data.previewImageUrl"
        playinline
        :style="{width: '280px'}"
      />
    </div>
  </div>
</template>

<script>
import VueViaudio from '@dongido/vue-viaudio'

export default {
  name: 'VideoMessage',
  components: {
    VueViaudio,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="css" scoped>
.video-message{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 8px;
  -webkit-box-orient: horizontal;
  box-sizing: border-box;
}
.chat-item-video {
  display: flex;
  position: relative;
  margin-left: 6px;
}
.chat-item-video>img {
  border-style: none;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  max-width: 250px;
  max-height: 250px;
}
</style>
