<template>
  <div
    :class="customBubbleClass.join(' ')"
  >
    <div
      :class="customDirectionClass"
      :dir="('direction' in data)? data.direction : ''"
    >
      <div
        class="t1Header"
        :style="backgroundColorHeader"
      >
        <component
          :is="isComponentHeader(data)"
          :data="data.header"
          @action="onAction"
        />
      </div>
      <div
        class="t1Hero"
        :style="backgroundColorHero"
      >
        <component
          :is="isComponentHero(data)"
          :data="data.hero"
          @action="onAction"
        />
      </div>
      <div
        class="t1Body ExHasFooter"
        :style="backgroundColorBody"
      >
        <component
          :is="isComponentBody(data)"
          :data="data.body"
          @action="onAction"
        />
      </div>
      <div
        class="t1Footer"
        :style="backgroundColorFooter"
      >
        <component
          :is="isComponentFooter(data)"
          :data="data.footer"
          @action="onAction"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Utils from '../utils'
import FlexBox from './FlexBox.vue'
import FlexText from './FlexText.vue'
import FlexSeparator from './FlexSeparator.vue'
import FlexSpacer from './FlexSpacer.vue'
import FlexFiller from './FlexFiller.vue'
import FlexImage from './FlexImage.vue'
import FlexIcon from './FlexIcon.vue'
import FlexButton from './FlexButton.vue'
import FlexVideo from './FlexVideo.vue'

export default {
  name: 'FlexBubble',
  components: {
    FlexBox,
    FlexText,
    FlexSeparator,
    FlexSpacer,
    FlexFiller,
    FlexImage,
    FlexIcon,
    FlexButton,
    FlexVideo,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customBubbleClass() {
      return Utils.getFlexBubbleClass(this.data)
    },
    customDirectionClass() {
      const { direction } = this.data
      const customClass = ['T1']
      customClass.push(`fx${(direction || 'ltr').toUpperCase()}`)
      return customClass
    },
    backgroundColorHeader() {
      if ('styles' in this.data) {
        if ('header' in this.data.styles) {
          if ('backgroundColor' in this.data.styles.header) {
            return (this.data.styles.header.backgroundColor) ? `background-color: ${this.data.styles.header.backgroundColor};` : ''
          }
        }
      }
      return ''
    },
    backgroundColorHero() {
      if ('styles' in this.data) {
        if ('hero' in this.data.styles) {
          if ('backgroundColor' in this.data.styles.hero) {
            return (this.data.styles.hero.backgroundColor) ? `background-color: ${this.data.styles.hero.backgroundColor};` : ''
          }
        }
      }
      return ''
    },
    backgroundColorBody() {
      if ('styles' in this.data) {
        if ('body' in this.data.styles) {
          if ('backgroundColor' in this.data.styles.body) {
            return (this.data.styles.body.backgroundColor) ? `background-color: ${this.data.styles.body.backgroundColor};` : ''
          }
        }
      }
      return ''
    },
    backgroundColorFooter() {
      if ('styles' in this.data) {
        if ('footer' in this.data.styles) {
          if ('backgroundColor' in this.data.styles.footer) {
            return (this.data.styles.footer.backgroundColor) ? `background-color: ${this.data.styles.footer.backgroundColor};` : ''
          }
        }
      }
      return ''
    },
  },
  methods: {
    isComponentHeader(data) {
      if ('header' in data) {
        if ('type' in data.header) {
          return `flex-${data.header.type}`
        }
      }
      return 'div'
    },
    isComponentHero(data) {
      if ('hero' in data) {
        if ('type' in data.hero) {
          return `flex-${data.hero.type}`
        }
      }
      return 'div'
    },
    isComponentBody(data) {
      if ('body' in data) {
        if ('type' in data.body) {
          return `flex-${data.body.type}`
        }
      }
      return 'div'
    },
    isComponentFooter(data) {
      if ('footer' in data) {
        if ('type' in data.footer) {
          return `flex-${data.footer.type}`
        }
      }
      return 'div'
    },
    onAction(action) {
      this.$emit('action', action)
    },
  },

}
</script>

<style>

</style>
