<template>
  <div
    class="line-chat-message LyMe"
    style="width: auto;"
  >
    <component
      :is="isComponent(data)"
      :data="data"
      @action="onAction"
    />
  </div>
</template>

<script>
import FlexMessage from './components/FlexMessage.vue'
import TextMessage from './components/TextMessage.vue'
import ImageMessage from './components/ImageMessage.vue'
import ImagemapMessage from './components/ImagemapMessage.vue'
import StickerMessage from './components/StickerMessage.vue'
import VideoMessage from './components/VideoMessage.vue'

export default {
  components: {
    FlexMessage,
    TextMessage,
    ImageMessage,
    ImagemapMessage,
    StickerMessage,
    VideoMessage,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    isComponent(data) {
      if ('type' in data) {
        return `${data.type}-message`
      }
      return 'div'
    },
    onAction(action) {
      this.$emit('action', action)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/components/line-chat-message/global.scss';
</style>
